<template>
  <div>
    <div class="contact-label">
      <span class="title">お知らせ一覧</span>
    </div>
    <div class='vue-infinite-loading'>
      <ul>
        <li v-for= "item in SortedItemsByPostId" :key="item.post_id">
          <tr>
            <td><div class='image'><img v-bind:src="item.image" ></div></td>
            <td><div class='subject'><router-link :to= "{ name: 'NewsPost' , params: {id: item.post_id }}"> {{ item.subject }} </router-link></div></td>
          </tr> 
        </li>
      </ul>
      <infinite-loading @infinite="infiniteHandler" spinner="bubbles" >
        <div slot="no-results">記事がありませんでした。</div>
        <div slot="no-more">全ての記事が表示されました。</div>
        <div slot="error">記事の取得中にエラーが発生しました。</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>

import InfiniteLoading from 'vue-infinite-loading'
export default {
  name: 'NewsPosts',
  components: {
    InfiniteLoading,
  },
  data(){
    return{
      items: [],
      latest_id: ''
    }
  },
  methods:{
    
    async infiniteHandler($state) {
      // 記事データの取得
      const data = await this.fetchData()
      if (!data) {
        $state.error()
      } else if (data.length) {
        this.items.push(...data)
        $state.loaded()
      } else if (data.length === 0) {
        $state.complete()
      }
    },
    // 記事データ取得処理
    async fetchData() {
      let data = [];
      await this.sleep(500);
      let num =  this.items && this.items.length;
      let id = this.latest_id - num //降順で取得するために変数を設定
      if (num + 1 < this.latest_id){
        for (let i = 0; i <= 10; i++) { //一度に取得する件数を指定
            await this.sleep(150);
            let post_id = id - i
            if( post_id > 0){
              this.$axios.get(this.$API_URL ,{
                params:{
                  post_id: post_id
                }
              }) 
              .then( response => {
                data.push({ post_id: response.data.post_id , subject: response.data.subject , image: response.data.image });
              })
            }else{
              break;
            }      
        }
      }
      return data
    },
    sleep(time) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    GetLatestID(){
        this.$axios.get(this.$API_URL,{
          params:{
            post_id: 'first'
          }
        }) 
        .then( response => {
          //console.log(response.data)
          this.latest_id = response.data.post_id
        })
    }

  },
  mounted:  function () {
      this.GetLatestID();
  },
  computed: {
      SortedItemsByPostId(){
      return this.items.slice().sort((a, b) => {
          return b.post_id - a.post_id;
    });}
  }
} 
</script>

<style scoped>

.contact-label {
  padding-top:90px;
  text-align: center;
}
.title{
  font-size: 1.5em;
  border-bottom:double;
  border-color:#c50e2f;
  border-width:5px;
}

.vue-infinite-loading{
  max-height: 600px;
  min-height: 320px;
  padding: 4% 20%;
  overflow-y: scroll;
}
.subject{
  padding: 60px;
}
</style>