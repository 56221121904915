<template>
  <div>
  <head>
    <title>Server Corp.</title>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <!-- Header -->
  <header id="header">
    <nav class="left">
      <router-link class="logo" to="/">Server Corp</router-link>
    </nav>
  </header>
  </div>
</template>

<script>
export default {
  name: 'GlobalHeader',
}
</script>

<style scoped>

#header {
  align-items: center;
  background: #fff;
  cursor: default;
  height: 6em;
  left: 0;
  line-height: 6em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10001;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.075);
  text-align: center;
}

#header .logo {
  color: #c50e2f;
  font-family: "Pacifico", cursive;
  font-size: 2.5em;
  letter-spacing: 2px;
  margin-top: -20px;
  text-decoration: none;
  display: inline-block;
}

#header nav {
  position: absolute;
  top: 0;
  height: inherit;
  line-height: inherit;
}

#header nav.left {
  left: 2em;
}

#header nav.right {
  right: 2em;
}

#header nav .button {
  padding: 0 2em;
  height: 3.25em;
  line-height: 3.25em;
  border: 1px solid rgb(197, 14, 47);
  border-radius: 20px;
  color: rgb(197, 14, 47);
}

#header nav .button:hover {
  background-color: rgb(247, 171, 0);
  color: #fff;
}

#header nav a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  background: #fff;
  transition: color 300ms, background-color 300ms;
}

#header nav.right a {
  right: 2em;
  padding: 0 0.5em;
}
</style>


