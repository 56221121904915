<template>
  <div>
    <div class="contact-label">
      <span class="title">お知らせ</span>
    </div>
    <div class='newspost'>
      <div class='image'><img :src="image" ></div>
      <div class='subject'> {{ subject }}</div>
      <div class='contents'>{{ contents }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewsPost',
  props: ['id'],
  data(){
    return{
      subject: '',
      contents: ''
    }
  },
  methods:{
    GetPost(post_id){
        this.$axios.get(this.$API_URL,{
          params:{
            post_id: post_id
          }
        }) 
        .then( response => {
          //console.log(response.data)
          this.subject = response.data.subject
          this.contents = response.data.contents
          this.image = response.data.image
        })
        .catch(() =>{
          this.subject = 'データの取得に失敗しました'
        })
        .finally(() =>{
          if (this.subject == null) {
            this.subject = '記事が見つかりませんでした'
          }
        })
    }
  },
  created: function () {
      this.GetPost(this.id);
    }
}
</script>

<style scoped>

.contact-label {
  padding-top:90px;
  text-align: center;
}
.title{
  font-size: 1.5em;
  border-bottom:double;
  border-color:#c50e2f;
  border-width:5px;
}
.newspost{
  padding: 3% 20%;
  border-radius: 11px;
  min-height: 600px; 
}
.subject{
  font-size: 2.0em;
  margin: 45px 0 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: solid;
  border-color:#767d85;
  border-width:2px;
}
.contents{
  margin: 85px 0 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  white-space: pre-line;
}
</style>