<template>
  <div>
    <!-- Banner -->
    <section class="banner">
        <div class="content">
        <h1>Server Corp.</h1>
        <p>クラウドで、世界を、もっと、働きやすく</p>
        </div>
    </section>
    <!-- News -->
     <section class="wrapper">
        <h1>お知らせ</h1>
        <div class="news">
          <div class="news-item" v-for="(item, index) in SortedItemsByPostId" :key="item.post_id">

              <div class="content">
              <h2>最新ニュース {{ index + 1 }}</h2>
              <div class="image">
                <img :src="item.image" >
              </div>
              <router-link :to= "{ name: 'NewsPost' , params: {id: item.post_id }}"> {{ item.subject }}  </router-link> 
              </div>
          </div>
        </div>
        <div class="newsposts">
          <router-link :to= "{ name: 'NewsPosts'}"> お知らせ一覧 </router-link>
        </div>
    </section> 
    </div>
</template>

<script>

export default {
  name: 'HomeView',
  data(){
    return{
      items: []
    }
  },
  methods:{
    GetPost(id){
        let item =  {post_id:'',subject:'',contents:''}
        let post_id = ''
        switch(id){
          case 0:
            post_id = 'first';
            break;
          case 1:
            post_id = 'second';
            break;
          case 2:
            post_id = 'third';
            break;
        }
        this.$axios.get(this.$API_URL ,{
          params:{
            post_id: post_id
          }
        }) 
        .then( response => {
          //console.log(response.data)
          item['post_id'] = response.data.post_id
          item['subject'] = response.data.subject
          item['contents'] = response.data.contents
          item['image'] = response.data.image
        })
        .catch(() =>{
          item['subject'] = 'データの取得に失敗しました'
        })
        .finally(() =>{
          //console.log(item)
          if (item['post_id'] === undefined){
             //pass
          }else{
            this.items.push(item)
          }
        });
      }
    },
    created:  function () {
      for (let i = 0; i < 3; i++) {
        this.GetPost(i);
      }}, 
    computed: {
      SortedItemsByPostId(){
      return this.items.slice().sort((a, b) => {
          return b.post_id - a.post_id;
      });}
    }  
  } 
</script>

<style scoped>
@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Montserrat:700|Open+Sans:300,400|Pacifico";

.banner {
  padding: 300px 0 150px 0;
  background-image: url(../assets/img/banner.jpg);
  background-color: rgba(255,255,255,0.7);
  background-blend-mode: lighten;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  text-align: center;
}

.banner h1 {
  font-size: 3em;
  font-weight: 700;
  color: #c50e2f;
  line-height: 1em;
  margin: 0 0 0.5em 0;
  padding: 0;
}

.banner p {
  font-size: 1.25em;
  font-family: Arial,sans-serif;
  font-weight: 400;
  color: black;
  margin-bottom: 1.75em;
}

.wrapper {
  padding: 6em 0 4em 0;
  text-align: center;
}

.wrapper h1 {
  text-align: center;
  padding: 30px 0px;
  font-size: 40px;
  font-weight: bold;
  font-family: sans-serif;
}

.wrapper h2 {
  font-weight: bold;
}

.wrapper h1, .wrapper h2 {
  color: #c50e2f;
}

.news {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.news-item {
  width: 30%;
}

.content {
  text-align: center;
  box-sizing: content-box;
}
</style>