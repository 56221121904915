<template>
  <div>
    <label v-if="!value" class="upload-content-space user-photo default">
     <input ref="file" class="file-button" type="file" @change="upload" />
      画像を選択する
    </label>

    <div v-if="value" class="uploaded">
        <label class="upload-content-space user-photo">
        <input ref="file" class="file-button" type="file" @change="upload" />
        <img class="user-photo-image" :src="value" />
        </label>
        <button type="button" class="delete-button" @click="deleteImage">
            削除する
        </button>  
    </div>
    <ul v-if="fileErrorMessages.length > 0" class="error-messages">
       <li v-for="(message, index) in fileErrorMessages" :key="index">
         {{ message }}
       </li>
     </ul>
  </div>
</template>

<script>
export default {
  name: 'ImgUpload',
  props: {
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            file: null,
            fileErrorMessages: []
        };
    },
    methods: {
        async upload(event) {
            const files = event.target.files || event.dataTransfer.files;
            const file = files[0];
            if (this.checkFile(file)) {
                const picture = await this.getBase64(file);
                this.$emit("input", picture);
            }
        },
        deleteImage() {
            this.$emit('input', null)
            this.$refs.file = null
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            })
        },
        checkFile(file) {
            let result = true;
            //console.log(file)
            this.fileErrorMessages = []
            const SIZE_LIMIT = 5000000; // 5MB
            const SIZE_MIN = 128000; //128kb
            // キャンセルしたら処理中断
            if (!file) {
                result = false;
            }
            // jpeg か png 関連ファイル以外は受付けない
            if (file.type !== "image/jpeg" && file.type !== "image/png") {
                this.fileErrorMessages.push('アップロードできるのは jpeg画像ファイル か png画像ファイルのみです。')
                result = false;
            }
            // 上限サイズより大きければ受付けない
            if (file.size > SIZE_LIMIT) {
                this.fileErrorMessages.push('アップロードできるファイルサイズは5MBまでです。')
                result = false;
            }
            if (file.size < SIZE_MIN) {
                this.fileErrorMessages.push('アップロードできるファイルサイズは128kB以上です。')
                result = false;
            }
            return result;
        },
    },
}
</script>

<style scoped>

.user-photo {
   cursor: pointer;
   outline: none;
 }
 
 .user-photo.default {
  display: inline-block;
  background-color: #E85A70;
  color: #FFF;
  width: 160px;
  padding: 0.8em;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  -webkit-tap-highlight-color: transparent;
  transition: .2s ease-out;
 }
 
 .user-photo.default:hover {
   cursor: pointer;
   text-decoration: none;
   transform: translateY(-4px);
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 3px 10px 0 rgba(0,0,0,0.12), 0 4px 7px -2px rgba(0,0,0,0.2);
 }
 
 .user-photo-image {
   max-width: 85px;
   display: block;
 }
 
 .user-photo-image:hover {
   opacity: 0.8;
 }
 
 .file-button {
   display: none;
 }
 
 .uploaded {
   align-items: center;
   display: flex;
 }
 .delete-button {
    margin: 30px;
    display: inline-block;
    background-color: #E85A70;
    color: #FFF;
    width: 160px;
    padding: 0.8em;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    -webkit-tap-highlight-color: transparent;
    transition: .2s ease-out;
 }
 
 .delete-button:hover {
    cursor: pointer;
    text-decoration: none;
    transform: translateY(-4px);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 3px 10px 0 rgba(0,0,0,0.12), 0 4px 7px -2px rgba(0,0,0,0.2);
 }
 
 .error-messages {
   color: #cf0000;
   list-style: none;
   margin: 0.4rem 0 0 0;
   padding: 0 0.2rem;
   font-size: 1.6rem;
 }
 
</style>

