<template>
  <div>
    <footer id="footer">
      <div class="inner">
        <h2>問合せ先</h2>
        <ul>
          <li><img src="../assets/img/tel.png" alt="" /> (000) 000-0000</li>
          <li><img src="../assets/img/mail.png" alt="" /> contact@example.com</li>
          <li><img src="../assets/img/address.png" alt="" /> Masumoto bldg 1-21 Agebacho Shinjuku Tokyo</li>
        </ul>
      </div>
      <div class="copyright">
        &copy; Copyright Servercorp
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
}
</script>

<style scoped>
#footer {
  padding: 2em 0 1em 0;
  background: linear-gradient(to right, rgb(197, 14, 47), rgb(247, 171, 0));
  text-align: center;
  color: #c8e7f0;
  position: static;
}

#footer h2 {
  color: #fff;
}

#footer ul li {
  padding: 0px;
}

#footer .copyright {
  display: inline-block;
  color: #fff;
  font-size: 0.75em;
  margin: 0 0 2em 0;
  padding: 0;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding: 1em 10em;
}
</style>


