import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import NewsUpload from '../views/NewsUpload.vue'
import NewsPost from '../views/NewsPost.vue'
import NewsPosts from '../views/NewsPosts.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'NewsPosts',
    component: NewsPosts
  },
  {
    path: '/news/new',
    name: 'NewsUpload',
    component: NewsUpload
  },
  {
    path: '/news/:id',
    name: 'NewsPost',
    component: NewsPost,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
